import { useTwitter, useTwitterStacked1, useTwitterStacked2, useTwitterStacked3, useTwitterStacked4 } from "../../utils/custom-hooks/image-hooks";

export default {
  data: {
    page: {
      theme: {
        logo: "red",
        type: "default",
      },
      title: "Twitter",
      uri: "/work/twitter-data/",
      // dateGmt: "2020-12-23T09:06:02",
      slug: "twitter-data",
      // modifiedGmt: "2021-05-18T21:09:29",
      seo: {
        canonical: "",
        metaDesc: "",
        opengraphDescription: "",
        opengraphSiteName: "Vivid",
        opengraphTitle: "Twitter - Vivid",
        opengraphType: "article",
        title: "Twitter - Vivid",
      },
      flexchildren: [
        {
          __typename: "HeroCta",
          backgroundGradientLight: "#1da1f2",
          backgroundGradientDark: "#005fd1",
          backgroundImagePosition: "right",
          backgroundSize: "contain",
          cta: null,
          ctaVariant: "light",
          description: "Sourcing, packaging, and monetizing Twitter’s data assets",
          descriptionColor: "#ffffff",
          title: "Monetizing insights through data alchemy",
          titleColor: "#ffffff",
          rightImage: {
            altText: "",
            useImage: useTwitter,
            localFile: {
              publicURL: "/static/fd9a8b62c24c4962f7a7a891ef10d744/Twitter-Case-Study-1.png",
              extension: "png",
              childImageSharp: {
                fluid: {
                  base64:
                    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAABYlAAAWJQFJUiTwAAADq0lEQVQoz2WSa0yTdxTG/wLaqaPcWmg7jaMwoLTavtwKrfRCLbdeYEVpYVZhGqBKl1pLwCUOmMs0cRsgbWlx6uhSp4wlduGipBTiGNPBYFdZtiw1Y7pkX9g0ZmN9+Z+9bu7DspP8kifn5Hk+nDwIrQBKoCejxFgmd2vGnnYGYXBsF+od7J2Vjmi+yYGIJsdmyYsO4oWTDmnjKUeG6fV2oebQkdLkKJqaQ0MFEjX6zwAAYsaloMStjLqSjh6ovnQdMlwBYDhDsG/wPDg6DWB56SjYTrwM9ZZD8L6nGWyHG0m0gclGUSyk3F2y4X+BKfEsxIhlmrRvOSF7dG6NPXIj8mrAQf40ySeXLiSQTaVpZEe9hLQf1kVWvHXQrZGtoh3ibY/9sjJ9jHiPJloplyFZcfE/ofEbaYhJTzEwTr8NDR8FI8FgK/714yr8YFaBl0bt+M3XTmG/pwcHp+fWv/o6DE22E79RNrqoUIkKtLUoV1WJVAo5klOhiLM9esvTCMVtocW2l9mNMH+9hVydN2LPaRW4j2fDj3dmwXthGNQVWtAaTFhbux+EJZURUb50XFGuGy5SlV8Ty1VDZWp1kkqlQki4v0MnP+6cOeAcA8vgJNnp7cU/T7Dw3aEUfM6ixF9cc4L1aCtmp/OhytgIpbpaLMiRgKy0HBosx0D9vBmkaj0USyVZUomE+kHX1X3my98stH8GYPscSPvlUey30cirZyrIc92t+I2WCtDIc/FzgnwwN1ux3lQDvLxcXFVnJJtbrH/arW24XFP9UEQQfCFBIKQ4+W6tZvDTBcvUA+i89Yi0X7kNfb1ucHr90Ns/uP6O7wqpM+zFWWnpoFSV4DRCiHMKCjFPLME1NdX4oLmO2svXRDm5RHpmJkJFPbNG/cWlRffUHfB/9wd0hcLhX+6HjREcsd69dx9WHz6CY21tsJObSmZl8MhNSckki/0M+VRsHJkpEER4QtF6Gi97LTWVS1RUVlKBXSMHFa/4fzjS1Awd5z+AhoGx7x9XKXBjKnv4w/Evx0M3v91rqv+dnsQEzo5UYLA5wOBsAyZFOo8POfliIPIKwGw+oPm7Mnn5Yr5gl6iFy+WezeLv6pNId9vnPrm9qW9gkN595qx8bGLC7fP5gs7+/mmX0xVyuVwzHo9nxu12Byk95RnwhLxe72QgEPAtLi5qUZFCjQplyn9LHoMBaEP+9+j+4RH65PRN5q35hWfDK/dY1C2eIoEi8YmOecJGiigK2vLy8ua/AOiXvWl2xvFVAAAAAElFTkSuQmCC",
                  aspectRatio: 1.6528925619834711,
                  src: "/static/fd9a8b62c24c4962f7a7a891ef10d744/f9ff4/Twitter-Case-Study-1.png",
                  srcSet:
                    "/static/fd9a8b62c24c4962f7a7a891ef10d744/5224a/Twitter-Case-Study-1.png 200w,\n/static/fd9a8b62c24c4962f7a7a891ef10d744/d786d/Twitter-Case-Study-1.png 400w,\n/static/fd9a8b62c24c4962f7a7a891ef10d744/f9ff4/Twitter-Case-Study-1.png 800w,\n/static/fd9a8b62c24c4962f7a7a891ef10d744/73f08/Twitter-Case-Study-1.png 1200w,\n/static/fd9a8b62c24c4962f7a7a891ef10d744/83ea2/Twitter-Case-Study-1.png 1498w",
                  srcWebp:
                    "/static/fd9a8b62c24c4962f7a7a891ef10d744/b0751/Twitter-Case-Study-1.webp",
                  srcSetWebp:
                    "/static/fd9a8b62c24c4962f7a7a891ef10d744/9e195/Twitter-Case-Study-1.webp 200w,\n/static/fd9a8b62c24c4962f7a7a891ef10d744/40a1d/Twitter-Case-Study-1.webp 400w,\n/static/fd9a8b62c24c4962f7a7a891ef10d744/b0751/Twitter-Case-Study-1.webp 800w,\n/static/fd9a8b62c24c4962f7a7a891ef10d744/a7c53/Twitter-Case-Study-1.webp 1200w,\n/static/fd9a8b62c24c4962f7a7a891ef10d744/021f4/Twitter-Case-Study-1.webp 1498w",
                  sizes: "(max-width: 800px) 100vw, 800px",
                  presentationWidth: 800,
                },
              },
            },
          },
          backgroundImage: {
            altText: "",
            name: "/twitter-background.svg",
            localFile: {
              publicURL: "/static/a6b36d7966d74c2822b95623b762c91f/twitter-bg-shape.svg",
              extension: "svg",
              childImageSharp: null,
            },
          },
          overlapSection: null,
        },
        {
          __typename: "Overview",
          client: "Twitter",
          description:
            "For nearly 7 years, Vivid has helped create and execute a unifying strategy for Twitter’s API program, working across multiple teams and technologies to transform the way they bring their data to market and drive engagement. <br />\r\n<br />\r\nOur partnership has spanned the full breadth of Vivid’s capabilities: we’ve reshaped strategic messaging, repackaged and marketed API solutions to enterprise clients, created industry conference presentations, and even built a self-service data platform. By productizing new initiatives from the top down, we’ve converted the potential of Twitter’s information assets into a self-sustaining profit center. <br />\r\n",
          industry: "Internet Software/Services",
          solutions:
            "Design, Branding, Copywriting, Engineering, Strategy, Architecture, Project Management",
          title: "Overview",
        },
        {
          __typename: "StackedImages",
          images: [
            {
              image: {
                altText: "",
                useImage: useTwitterStacked1,
                localFile: {
                  publicURL:
                    "/static/056f4b2b73874e138c9749a008c26303/dfuzr_success_story_twitter_ui_design_thumbnail_2-3.jpg",
                  extension: "jpg",
                  childImageSharp: {
                    fluid: {
                      base64:
                        "data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMEAQX/xAAVAQEBAAAAAAAAAAAAAAAAAAABAP/aAAwDAQACEAMQAAABRRDotOeN/8QAGRAAAwADAAAAAAAAAAAAAAAAAQIDERIT/9oACAEBAAEFAuVdxFVNLTBa9C5tTDMSf//EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAgBAwEBPwFNf//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EAB0QAAIBBAMAAAAAAAAAAAAAAAABAhEhMnEDEmH/2gAIAQEABj8CipxW0PvgUXG7ejdaaMi5/8QAGRAAAwEBAQAAAAAAAAAAAAAAAAERIUGB/9oACAEBAAE/IWuYLpwzBas6ILiJo0TVjHbtWFBD8P/aAAwDAQACAAMAAAAQ78//xAAXEQADAQAAAAAAAAAAAAAAAAAAAREh/9oACAEDAQE/EHZkGjT/xAAXEQADAQAAAAAAAAAAAAAAAAAAAREh/9oACAECAQE/EFLo5cP/xAAbEAEBAAIDAQAAAAAAAAAAAAABEQAhMWGRQf/aAAgBAQABPxB/2nAIN2dYil6jWmcN0aPMBgFcl57cELYIIT7rHUYEIRPM2MJKjP/Z",
                      aspectRatio: 1.7391304347826086,
                      src: "/static/056f4b2b73874e138c9749a008c26303/a1eb1/dfuzr_success_story_twitter_ui_design_thumbnail_2-3.jpg",
                      srcSet:
                        "/static/056f4b2b73874e138c9749a008c26303/4fa6c/dfuzr_success_story_twitter_ui_design_thumbnail_2-3.jpg 200w,\n/static/056f4b2b73874e138c9749a008c26303/64b17/dfuzr_success_story_twitter_ui_design_thumbnail_2-3.jpg 400w,\n/static/056f4b2b73874e138c9749a008c26303/a1eb1/dfuzr_success_story_twitter_ui_design_thumbnail_2-3.jpg 800w,\n/static/056f4b2b73874e138c9749a008c26303/a6c62/dfuzr_success_story_twitter_ui_design_thumbnail_2-3.jpg 1200w,\n/static/056f4b2b73874e138c9749a008c26303/70ff9/dfuzr_success_story_twitter_ui_design_thumbnail_2-3.jpg 1600w,\n/static/056f4b2b73874e138c9749a008c26303/cfa73/dfuzr_success_story_twitter_ui_design_thumbnail_2-3.jpg 2260w",
                      srcWebp:
                        "/static/056f4b2b73874e138c9749a008c26303/b0751/dfuzr_success_story_twitter_ui_design_thumbnail_2-3.webp",
                      srcSetWebp:
                        "/static/056f4b2b73874e138c9749a008c26303/9e195/dfuzr_success_story_twitter_ui_design_thumbnail_2-3.webp 200w,\n/static/056f4b2b73874e138c9749a008c26303/40a1d/dfuzr_success_story_twitter_ui_design_thumbnail_2-3.webp 400w,\n/static/056f4b2b73874e138c9749a008c26303/b0751/dfuzr_success_story_twitter_ui_design_thumbnail_2-3.webp 800w,\n/static/056f4b2b73874e138c9749a008c26303/a7c53/dfuzr_success_story_twitter_ui_design_thumbnail_2-3.webp 1200w,\n/static/056f4b2b73874e138c9749a008c26303/80926/dfuzr_success_story_twitter_ui_design_thumbnail_2-3.webp 1600w,\n/static/056f4b2b73874e138c9749a008c26303/b6083/dfuzr_success_story_twitter_ui_design_thumbnail_2-3.webp 2260w",
                      sizes: "(max-width: 800px) 100vw, 800px",
                      presentationWidth: 800,
                    },
                  },
                },
              },
            },
            {
              image: {
                altText: "",
                useImage: useTwitterStacked2,
                localFile: {
                  publicURL:
                    "/static/aecc935d0bad561ece118f10a76af4e5/Twitter-Success-Story-2.jpg",
                  extension: "jpg",
                  childImageSharp: {
                    fluid: {
                      base64:
                        "data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAMBAgQF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAv/aAAwDAQACEAMQAAAB6ydimILhX//EABkQAAIDAQAAAAAAAAAAAAAAAAECABESA//aAAgBAQABBQJrmiq8jaxkDRRkf//EABgRAAIDAAAAAAAAAAAAAAAAAAABAjFB/9oACAEDAQE/AWtI0f/EABYRAQEBAAAAAAAAAAAAAAAAAAEAMf/aAAgBAgEBPwEZ2//EABgQAAMBAQAAAAAAAAAAAAAAAAABERAx/9oACAEBAAY/AokR9K9h/8QAGhAAAgMBAQAAAAAAAAAAAAAAAREAITFhEP/aAAgBAQABPyE+wNcG1mNcJ3PCAJdVUGs12f/aAAwDAQACAAMAAAAQ0w//xAAXEQADAQAAAAAAAAAAAAAAAAAAATER/9oACAEDAQE/EHwyGn//xAAXEQADAQAAAAAAAAAAAAAAAAAAATER/9oACAECAQE/EFRoth//xAAcEAEBAQACAwEAAAAAAAAAAAABEQAhMUFxgbH/2gAIAQEAAT8QO1c4TIzOXVEV+vzOoVfTd8HSzIRU4cZdDz23/9k=",
                      aspectRatio: 1.7391304347826086,
                      src: "/static/aecc935d0bad561ece118f10a76af4e5/a1eb1/Twitter-Success-Story-2.jpg",
                      srcSet:
                        "/static/aecc935d0bad561ece118f10a76af4e5/4fa6c/Twitter-Success-Story-2.jpg 200w,\n/static/aecc935d0bad561ece118f10a76af4e5/64b17/Twitter-Success-Story-2.jpg 400w,\n/static/aecc935d0bad561ece118f10a76af4e5/a1eb1/Twitter-Success-Story-2.jpg 800w,\n/static/aecc935d0bad561ece118f10a76af4e5/a6c62/Twitter-Success-Story-2.jpg 1200w,\n/static/aecc935d0bad561ece118f10a76af4e5/70ff9/Twitter-Success-Story-2.jpg 1600w,\n/static/aecc935d0bad561ece118f10a76af4e5/cfa73/Twitter-Success-Story-2.jpg 2260w",
                      srcWebp:
                        "/static/aecc935d0bad561ece118f10a76af4e5/b0751/Twitter-Success-Story-2.webp",
                      srcSetWebp:
                        "/static/aecc935d0bad561ece118f10a76af4e5/9e195/Twitter-Success-Story-2.webp 200w,\n/static/aecc935d0bad561ece118f10a76af4e5/40a1d/Twitter-Success-Story-2.webp 400w,\n/static/aecc935d0bad561ece118f10a76af4e5/b0751/Twitter-Success-Story-2.webp 800w,\n/static/aecc935d0bad561ece118f10a76af4e5/a7c53/Twitter-Success-Story-2.webp 1200w,\n/static/aecc935d0bad561ece118f10a76af4e5/80926/Twitter-Success-Story-2.webp 1600w,\n/static/aecc935d0bad561ece118f10a76af4e5/b6083/Twitter-Success-Story-2.webp 2260w",
                      sizes: "(max-width: 800px) 100vw, 800px",
                      presentationWidth: 800,
                    },
                  },
                },
              },
            },
          ],
        },
        {
          __typename: "Quote",
          authorAndTitleCompany:
            "Prashant Sridharan, Senior Director of Developer & Platform Relations at Twitter",
          quoteText:
            "The Vivid team consistently bests our expectations…delivering finished products that look and function great, and result in new business opportunities for us.",
        },
        {
          __typename: "StackedImages",
          images: [
            {
              image: {
                altText: "",
                useImage: useTwitterStacked3,
                localFile: {
                  publicURL:
                    "/static/72c95e5917931ee67bef9d41106b76df/dfuzr_success_story_twitter_ui_design_thumbnail_4.jpg",
                  extension: "jpg",
                  childImageSharp: {
                    fluid: {
                      base64:
                        "data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAEFAgP/xAAVAQEBAAAAAAAAAAAAAAAAAAACA//aAAwDAQACEAMQAAABm9pzc0ZA/wD/xAAaEAACAgMAAAAAAAAAAAAAAAABAgADEBES/9oACAEBAAEFAqUDx6VCTZE6OP/EABgRAAIDAAAAAAAAAAAAAAAAAAECEBEx/9oACAEDAQE/ASpvY//EABgRAAIDAAAAAAAAAAAAAAAAAAEQERIx/9oACAECAQE/AbCMX//EABkQAAMAAwAAAAAAAAAAAAAAAAAQIQERMf/aAAgBAQAGPwK5N1Q6v//EABwQAQACAQUAAAAAAAAAAAAAAAEAETEQIUFRcf/aAAgBAQABPyG9uKOIzKk7il4hmE8ihunT/9oADAMBAAIAAwAAABDA3//EABgRAAIDAAAAAAAAAAAAAAAAAAARATFR/9oACAEDAQE/EJL0wR//xAAXEQEAAwAAAAAAAAAAAAAAAAABEBEh/9oACAECAQE/EAKuo//EABoQAQACAwEAAAAAAAAAAAAAAAEAESFhgXH/2gAIAQEAAT8QxMgTqPsDFvJeTc9VUTBG1gT/2Q==",
                      aspectRatio: 1.7391304347826086,
                      src: "/static/72c95e5917931ee67bef9d41106b76df/a1eb1/dfuzr_success_story_twitter_ui_design_thumbnail_4.jpg",
                      srcSet:
                        "/static/72c95e5917931ee67bef9d41106b76df/4fa6c/dfuzr_success_story_twitter_ui_design_thumbnail_4.jpg 200w,\n/static/72c95e5917931ee67bef9d41106b76df/64b17/dfuzr_success_story_twitter_ui_design_thumbnail_4.jpg 400w,\n/static/72c95e5917931ee67bef9d41106b76df/a1eb1/dfuzr_success_story_twitter_ui_design_thumbnail_4.jpg 800w,\n/static/72c95e5917931ee67bef9d41106b76df/4336c/dfuzr_success_story_twitter_ui_design_thumbnail_4.jpg 1130w",
                      srcWebp:
                        "/static/72c95e5917931ee67bef9d41106b76df/b0751/dfuzr_success_story_twitter_ui_design_thumbnail_4.webp",
                      srcSetWebp:
                        "/static/72c95e5917931ee67bef9d41106b76df/9e195/dfuzr_success_story_twitter_ui_design_thumbnail_4.webp 200w,\n/static/72c95e5917931ee67bef9d41106b76df/40a1d/dfuzr_success_story_twitter_ui_design_thumbnail_4.webp 400w,\n/static/72c95e5917931ee67bef9d41106b76df/b0751/dfuzr_success_story_twitter_ui_design_thumbnail_4.webp 800w,\n/static/72c95e5917931ee67bef9d41106b76df/a8db0/dfuzr_success_story_twitter_ui_design_thumbnail_4.webp 1130w",
                      sizes: "(max-width: 800px) 100vw, 800px",
                      presentationWidth: 800,
                    },
                  },
                },
              },
            },
            {
              image: {
                altText: "",
                useImage: useTwitterStacked4,
                localFile: {
                  publicURL:
                    "/static/13ae23548c169b71b0d6b283ec6af8c6/Twitter-Success-Story-3.jpg",
                  extension: "jpg",
                  childImageSharp: {
                    fluid: {
                      base64:
                        "data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIEBf/EABYBAQEBAAAAAAAAAAAAAAAAAAMBAv/aAAwDAQACEAMQAAABgedotplmh//EAB0QAAEDBQEAAAAAAAAAAAAAAAACAxMBBBESITL/2gAIAQEAAQUCYyb1bVKMerji5FH/xAAXEQADAQAAAAAAAAAAAAAAAAACEBES/9oACAEDAQE/AYOKv//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABkQAQADAQEAAAAAAAAAAAAAAAABETFBcf/aAAgBAQAGPwK3vGSpFNf/xAAdEAEAAgEFAQAAAAAAAAAAAAABABExIUFRYYHB/9oACAEBAAE/ISCmMdwpBUhd+cXtNN2GY8uf/9oADAMBAAIAAwAAABBYD//EABgRAQADAQAAAAAAAAAAAAAAAAEAESFR/9oACAEDAQE/EBHTZRyf/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAERUf/aAAgBAgEBPxCulen/xAAcEAEAAgMBAQEAAAAAAAAAAAABABEhMVFxYbH/2gAIAQEAAT8QaNSaA5PznsbKRpNi+9/ZVFBrNLLicgZTcWtZem2PdHjP/9k=",
                      aspectRatio: 1.7391304347826086,
                      src: "/static/13ae23548c169b71b0d6b283ec6af8c6/a1eb1/Twitter-Success-Story-3.jpg",
                      srcSet:
                        "/static/13ae23548c169b71b0d6b283ec6af8c6/4fa6c/Twitter-Success-Story-3.jpg 200w,\n/static/13ae23548c169b71b0d6b283ec6af8c6/64b17/Twitter-Success-Story-3.jpg 400w,\n/static/13ae23548c169b71b0d6b283ec6af8c6/a1eb1/Twitter-Success-Story-3.jpg 800w,\n/static/13ae23548c169b71b0d6b283ec6af8c6/4336c/Twitter-Success-Story-3.jpg 1130w",
                      srcWebp:
                        "/static/13ae23548c169b71b0d6b283ec6af8c6/b0751/Twitter-Success-Story-3.webp",
                      srcSetWebp:
                        "/static/13ae23548c169b71b0d6b283ec6af8c6/9e195/Twitter-Success-Story-3.webp 200w,\n/static/13ae23548c169b71b0d6b283ec6af8c6/40a1d/Twitter-Success-Story-3.webp 400w,\n/static/13ae23548c169b71b0d6b283ec6af8c6/b0751/Twitter-Success-Story-3.webp 800w,\n/static/13ae23548c169b71b0d6b283ec6af8c6/a8db0/Twitter-Success-Story-3.webp 1130w",
                      sizes: "(max-width: 800px) 100vw, 800px",
                      presentationWidth: 800,
                    },
                  },
                },
              },
            },
          ],
        },
      ],
    },
  },
};
